<template>
    <Exams />
</template>
<script>
import Exams from "@/components/auth/exams/Exams.vue";
export default {
    components: {
        Exams,
    }
}
</script>